export const SHIPPING_STATUS = (type, e) => {
  if (type === '1') {
    switch (e) {
      case '1':
        return 'รอดำเนินการ';
      case '2':
        return 'ใช้โค๊ดแล้ว';
      case '3':
      case '4':
        return 'สำเร็จ';
      default:
        break;
    }
  } else {
    switch (e) {
      case '1':
        return 'รอดำเนินการ';
      case '2':
        return 'เตรียมจัดส่ง';
      case '3':
        return 'จัดส่ง';
      case '4':
        return 'สำเร็จ';
      default:
        break;
    }
  }
};
